import React from "react"

import Layout from "../components/layout"
import Parasol from "../components/parasol"
import Seo from "../components/seo"

const PoncotsuFound = ({ location }) => (
  <div className="body">
    <Seo
      pagetitle="404 Poncotsu Found"
      pagepath={location.pathname}
    />
    <Parasol />
    <Layout>
      <main className="poncotsuFound">
        <h2 className="poncotsuFound__title">404 Poncotsu Found</h2>
        <p className="poncotsuFound__text">お探しのページを見つけられなかったぽんこつが見つかりました。</p>
      </main>
    </Layout>
  </div>
)

export default PoncotsuFound