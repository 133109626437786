import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Parallax from "react-rellax"

const Parasol = () => (
  <div className="parasol">
    <p className="parasol__sea"><StaticImage src="../images/parasol_sea.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__wave2"><StaticImage src="../images/parasol_wave2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__cloud1"><StaticImage src="../images/parasol_cloud1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__cloud2"><StaticImage src="../images/parasol_cloud2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__wave1"><StaticImage src="../images/parasol_wave1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__grass"><StaticImage src="../images/parasol_grass.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__flower1Shadow"><Parallax speed={0.2}><StaticImage src="../images/parasol_flower1_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></Parallax></p>
    <p className="parasol__flower1"><StaticImage src="../images/parasol_flower1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__flower2Shadow"><Parallax speed={0.2}><StaticImage src="../images/parasol_flower2_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></Parallax></p>
    <p className="parasol__flower2"><StaticImage src="../images/parasol_flower2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__surfboardShadow"><Parallax speed={0.2}><StaticImage src="../images/parasol_surfboard_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></Parallax></p>
    <p className="parasol__surfboard"><StaticImage src="../images/parasol_surfboard.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__sheet"><StaticImage src="../images/parasol_sheet.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__bearShadow"><Parallax speed={0.2}><StaticImage src="../images/parasol_bear_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></Parallax></p>
    <p className="parasol__bear"><StaticImage src="../images/parasol_bear.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__parasol1Shadow"><Parallax speed={1}><StaticImage src="../images/parasol_parasol1_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></Parallax></p>
    <p className="parasol__parasol1"><StaticImage src="../images/parasol_parasol1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__parasol2Shadow"><Parallax speed={1}><StaticImage src="../images/parasol_parasol2_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></Parallax></p>
    <p className="parasol__parasol2"><StaticImage src="../images/parasol_parasol2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__parasol3Shadow"><Parallax speed={1}><StaticImage src="../images/parasol_parasol3_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></Parallax></p>
    <p className="parasol__parasol3"><StaticImage src="../images/parasol_parasol3.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__parasol4Shadow"><Parallax speed={1}><StaticImage src="../images/parasol_parasol4_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></Parallax></p>
    <p className="parasol__parasol4"><StaticImage src="../images/parasol_parasol4.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__parasol5Shadow"><Parallax speed={1}><StaticImage src="../images/parasol_parasol5_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></Parallax></p>
    <p className="parasol__parasol5"><StaticImage src="../images/parasol_parasol5.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__pants"><StaticImage src="../images/parasol_pants.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__sandals"><StaticImage src="../images/parasol_sandals.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__lens"><StaticImage src="../images/parasol_lens.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__smallCloud1"><StaticImage src="../images/parasol_cloud1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__smallCloud2"><StaticImage src="../images/parasol_cloud2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__goggles"><StaticImage src="../images/parasol_goggles.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
    <p className="parasol__filter"><StaticImage src="../images/parasol_filter.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="parasol__image" /></p>
  </div>
)

export default Parasol